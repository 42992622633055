/* eslint-disable max-len */
import React, {useState, useContext} from 'react';
import {Grid, Typography, Box} from '@mui/material';
import Eddie from '../../assets/images/boy-persona.png';
import Annie from '../../assets/images/girl-persona.png';
import PropTypes from 'prop-types';
import DemoImg from './demoPic';
import SelectedIdContext from '../../models/selectedContext';

const DemoPersona = ({onSelect}) => {
  const {selectedId, setSelectedId} = useContext(SelectedIdContext);
  const [hoverId, setHoverId] = useState(null);

  const handleSelect = (id) => {
    selectedId === id? setSelectedId(undefined) : setSelectedId(id);
    if (onSelect) {
      onSelect(id);
    }
  };
  const handleHover = (id) => {
    setHoverId(id);
  };

  const gridStyle = (id) => ({
    'borderRadius': '20px',
    'border': selectedId === id ? '3px solid #000' : '1px solid #EAE8E4', // thicker border if selected
    'p': '20px',
    'boxShadow': `0px 2px 4px rgba(0, 0, 0, 0.1)`,
    'bgcolor': hoverId === id ? 'lightgrey' : 'transparent', // background color on hover
    '&:hover': {
      bgcolor: 'lightgrey',
      cursor: 'pointer',
    },
    'overflowY': 'auto',
  });

  return (
    <Box display='flex'
      flexDirection={'column'}>
      <Typography variant='h6' align='center' m={2}>
              Hello and Welcome to the ProSocial Application Demo.
      </Typography>
      <Typography variant='h6' align='center'>
              To experience how ProSocial works for different individuals,
               select a demo profile below.
      </Typography>

      <Box display='flex'
        flexDirection={'row'}
        padding={10}
        overflowY='auto'>

        <p></p>
        <Grid
          id='Eddie'
          container
          m={2}
          alignItems="start"
          justifyContent="start"
          sx={gridStyle('Eddie')}
          onClick={() => handleSelect('Eddie')}
          onMouseEnter={() => handleHover('Eddie')}
          onMouseLeave={() => setHoverId(null)}
        >
          <Grid item xs={12}
            alignItems={'start'}
            display='flex'
            flexDirection={'row'}>
            <DemoImg img={Eddie}></DemoImg>
            <Box flexGrow={1} p={2}>
              <Typography variant="h6" gutterBottom>
              Persona: Eddie
              </Typography>
              <Typography variant="body2">
            Eddie is 14 years old and loves to play online video games like Fortnight and Roblox.
                  He is extremely outgoing and loves meeting new people.  He is expressive and great
                   at telling others what he is thinking and feeling.  He loves to talk about
                    his interests and teach others. Eddie can be very kind and encouraging,
                     especially with younger friends.  He often gets excited to speak and
                      has a difficult time following conversations about topics he isn’t interested in.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} >
            <Box
              width="100%"
              textAlign={'left'}
              bgcolor={'transparent'}
            >
              <Typography variant="body2">
                <p></p>
                <strong>Eddie&apos;s Goal: </strong>
            Find more people to play Fortnight with.

                <p></p>
                <strong>Our Goal: </strong>
            Help Eddie find strategies to listen, remember and reflect on conversational details, while bringing others along with his interests and ideas.


                <p></p>
                <strong>Talk Targets: </strong>
                <ul>
                  <li><strong>Ask for Input:</strong> Ask someone for their opinions, advice or feedback on a topic.</li>
                  <li><strong>Reflect: </strong> Paraphrase what someone else has said back to them.</li>
                  <li><strong>Pause before speaking: </strong> Wait at least 2 seconds before responding to another person’s topic or question.</li>
                </ul>
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid
          id='Annie'
          container
          alignItems="start"
          justifyContent="start"
          m={2}
          sx={gridStyle('Annie')}
          onClick={() => handleSelect('Annie')}
          onMouseEnter={() => handleHover('Annie')}
          onMouseLeave={() => setHoverId(null)}>
          <Grid item xs={12}
            alignItems={'start'}
            display='flex'
            flexDirection={'row'}>
            <DemoImg img={Annie}></DemoImg>
            <Box flexGrow={1} p={2}>
              <Typography variant="h6" gutterBottom>
              Persona: Annie
              </Typography>
              <Typography variant="body2">
            Annie is 12 years old and loves robots and Disney movies.
              She is sensitive, empathetic, and enjoys getting to know people one-on-one.
                She gets nervous in groups and sometimes needs extra time to translate her thoughts into words.
                  She is a great listener and often remembers many details about the people she meets.
                    She wants to overcome her social anxiety and participate more in conversations.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} >
            <Box
              width="100%"
              textAlign={'left'}
              bgcolor={'transparent'}
            >
              <Typography variant="body2">
                <p></p>
                <strong>Annie&apos;s Goal: </strong>
              Make a best friend that she can watch movies with and talk to about anything.


                <p></p>
                <strong>Our Goal: </strong>
              Help Annie build on her strengths as an empathetic listener to contribute to conversations and build mutual relationships with others.

                <p></p>
                <strong>Talk Targets: </strong>
                <ul>
                  <li><strong>Speak up early:</strong> Make a contribution or comment within the first 5 minutes of conversation.</li>
                  <li><strong>Initiate a new topic:</strong> bring up a new idea to share with the group.</li>
                  <li><strong>Share your thoughts:</strong> Share a personal experience or opinion in response to another person.</li>
                </ul>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

DemoPersona.propTypes = {
  onSelect: PropTypes.func,
};

DemoPersona.defaultProps = {
  onSelect: (id) => {
    console.log(id);
  }, // No-op function as a default
};

export default DemoPersona;
