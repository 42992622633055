/* eslint-disable linebreak-style */
import React from 'react';
import {Box, Typography, useTheme} from '@mui/material';
import PropTypes from 'prop-types';
import AlertMessage from './alertMessage';
import Favorite from '@mui/icons-material/Favorite';
import NotificationsActiveOutlined
  from '@mui/icons-material/NotificationsActiveOutlined';
import CrisisAlert from '@mui/icons-material/CrisisAlert';
import logger from '../../logger';
const chatData = [
  {icon: 'love', label: 'Coach Bot', timestamp: '2023-04-01T15:30:00.000Z',
    message: 'Positive feedback will look like this'},
  {icon: 'notify', label: 'Coach Bot', timestamp: '2023-04-01T15:30:00.000Z',
    message: 'Suggestions will look like this'},
  {icon: 'target', label: 'Talk Target', timestamp: '2023-04-01T15:30:00.000Z',
    message: 'talk targets will look like this'},
  {icon: 'alert', label: '', timestamp: '2023-04-01T15:30:00.000Z',
    message: 'Alert messages will look like this'},
];

const ChatMessage = React.memo(({icon, message, label, timestamp}) => {
  const theme = useTheme();
  logger.info(`populating message with ${timestamp}`);

  const formattedTimestamp = new Date(timestamp).toLocaleString();
  const messageColor =
    icon === 'notify' ? theme.palette.secondary.main :
    icon === 'love' ? theme.palette.primary.main :
    icon === 'target' ? theme.palette.primary.green :
    theme.palette.primary.darkText;


  return (
    <Box sx={{display: 'flex', flexWrap: 'wrap', alignItems: 'center', m: 1}}>
      <Box sx={{display: 'flex',
        flexWrap: 'nowrap', alignItems: 'start', m: 1, flexDirection: 'row'}}>
        {/* Icon */}
        {icon === 'notify' &&
       <NotificationsActiveOutlined sx={{color: messageColor}}
         fontSize='medium' />}
        {icon === 'love' &&
       <Favorite sx={{color: messageColor}} fontSize='medium' />}
        {icon === 'target' &&
       <CrisisAlert sx={{color: messageColor}} fontSize='medium' />}
        {/* Label */}
        {icon === 'alert' &&
       <AlertMessage message={message} />
        }
        {icon != 'alert' && label && <Box>
          <Typography
            variant='body3'
            sx={{
              mx: 1,
              color: messageColor,
            }}>
            {label}</Typography>
          <Typography variant='body4'>{formattedTimestamp}</Typography></Box>
        }

      </Box>
      <Box>
        {icon != 'alert' &&

      <Typography variant='caption'
        sx={{color: 'black', mx: 1}}>{message}</Typography>
        }
      </Box>
    </Box>
  );
});

ChatMessage.displayName = 'ChatMessage';


ChatMessage.propTypes = {
  icon: PropTypes.string,
  message: PropTypes.string,
  label: PropTypes.string,
  timestamp: PropTypes.string,
};

ChatMessage.defaultProps = {
  icon: chatData.icon,
  message: chatData.message,
  label: chatData.label,
  timestamp: chatData.timestamp,
};

export default ChatMessage;
