import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import ContextualHeader from '../sections/contextualHeader';
import DesktopFooter from '../sections/footer';
import Hero from '../sections/heroText';
import Carousel from '../sections/carousel';
import TeamGrid from '../sections/team';
import ProductGrid from '../sections/product';
import {Box, Link, Typography} from '@mui/material';
import {HorizontalButtonGroup} from '../navigation/horizontalButtonGroup';
import {useAuth0} from '@auth0/auth0-react';
import logger from '../../logger';
const prodLinks = [
  {name: 'Join Waitlist', path: '/waitlist', variant: 'contained'},
  {name: 'Learn More', path: '/product', variant: 'contained'},

];

/**
 * Renders the Home page.
/**
 * Renders the Home page content.
 * @return {JSX.Element} The Home page content.
 */
export default function Home() {
  const {
    user,
    isAuthenticated,
  } = useAuth0();

  logger.info(`DEBUG: checking values from useAuth
   provider: user: ${JSON.stringify(user)} isAuthenticated:${isAuthenticated}`);
  return (
    <div>
      <Box sx={{position: 'sticky',
        top: 0, zIndex: 1000}}> {/* <-- Added styling here */}
        <ContextualHeader />
      </Box>
      <Hero ></Hero>
      <Box width='100%'
        mx='auto' my='50px'
        sx={{display: 'flex',
          alignContent: 'center',
          justifyContent: 'center'}}>
        <Carousel>
        </Carousel>
      </Box>
      <Box width='90%' mx='auto' my='100px'>
        <Typography variant='h6' align='center' my='50px'>Our Team</Typography>
        <Typography variant='body1'
          align='center' textAlign='center'
          my='50px'>We are a group of data scientists, engineers, and clinicians
        with deep roots in the neurodiverse community. We actively seek out
        talented individuals who learn and think differently. Interested in
        joining our mission?
          <Link component={RouterLink}
            to='/contact'>Contact Us </Link></Typography>
        <br></br><br></br>
        <TeamGrid></TeamGrid>
        <br></br><br></br>
      </Box>
      <Box width='90%' mx='auto' my='100px'>
        <ProductGrid imgWidth="80"></ProductGrid>
        <br></br><br></br>
        <HorizontalButtonGroup
          links={prodLinks}
          groupVariant="contained"
          color="primary"
        >

        </HorizontalButtonGroup>
      </Box>
      <DesktopFooter> </DesktopFooter>
    </div>
  );
};
