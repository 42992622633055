import React from 'react';
import ResponsiveDrawer from '../navigation/responsiveDrawer';
import FocusText from '../sections/productfeatures';
import {featureLinks} from '../../models/navlinks';
import logger from '../../logger';
/**
 * Renders the Product page component.
 * @return {JSX.Element} The Product page component.
 */
export default function Product() {
  return (
    <div>
      {logger.info({featureLinks})}
      <ResponsiveDrawer
        InnerComponent={FocusText}
        links={featureLinks}
        title="Product Features"
      />
    </div>
  );
}
