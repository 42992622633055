import React from 'react';
import {Box, Grid, Typography} from '@mui/material';
import {
  featuresData,
  skillsData,
  modelData,
  widgetsData,
} from '../../models/product';
import FocusAreas from '../sections/focusareas';
import ModelCard from '../cards/models';
import WidgetCard from '../cards/productWidgets';
import DesktopFooter from './footer';


const FocusText = (data) => {
  return (
    <section id="features">
      <Grid container spacing={3}>
        {featuresData.map((data, index) => (
          <React.Fragment key={index}>
            <Grid item xs={3}>
              <Typography
                id={data.header}
                variant="h6">
                {data['header']}
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="body2">{data['text']}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                padding="2"
              >
                <img
                  src={data.image}
                  alt={data.description}
                  style={{minWidth: '200px', maxWidth: '500px'}}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              {data['header'] === 'Focus Areas and Skills' ? (
                <FocusAreas data={skillsData}> </FocusAreas>
              ) : data['header'] === 'Our Models' ? (
                <ModelCard data={modelData}> </ModelCard>
              ) : data['header'] === 'Coaching Widgets' ? (
                <WidgetCard data={widgetsData}> </WidgetCard>
              ) : null}
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
      <DesktopFooter> </DesktopFooter>
    </section>
  );
};

export default FocusText;
