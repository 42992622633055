import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {useMediaTrack} from '@daily-co/daily-react-hooks';

/**
 * Video Tile component
 * @param {string} id - The id of the participant
 * @param {boolean} isScreenShare
 * @param {boolean} muted
 * Whether the participant is sharing their screen
 * @return {JSX.Element}
 */
export default function VideoTile({id, muted}) {
  const videoTrack = useMediaTrack(id, 'video');
  const audioTrack = useMediaTrack(id, 'audio');
  const videoElement = useRef(null);
  const audioElement = useRef(null);

  useEffect(() => {
    /* The track is ready to be played. We can show video
    of the remote participant in the UI. */
    if (videoTrack?.state === 'playable') {
      videoElement.current &&
        (videoElement.current.srcObject =
          videoTrack && new MediaStream([videoTrack.persistentTrack]));
    }
  }, [videoTrack]);

  useEffect(() => {
    if (audioTrack?.state === 'playable') {
      audioElement?.current &&
        (audioElement.current.srcObject =
          audioTrack && new MediaStream([audioTrack.persistentTrack]));
    }
  }, [audioTrack]);

  return (
    <div>
      {videoTrack && <video width="100%" height="auto"
        autoPlay muted playsInline ref={videoElement}/>}
      {!muted && audioTrack && <audio autoPlay ref={audioElement} />}
    </div>
  );
}


VideoTile.propTypes = {
  id: PropTypes.string.isRequired,
  muted: PropTypes.bool.isRequired,
};

VideoTile.defaultProps={
  id: '0',
};
