import React from 'react';
import {Box, Grid, Paper, Typography} from '@mui/material';
import PropTypes from 'prop-types';

// Separate moods by quadrants
const quadrantMoods = [
  // Red Quadrant (Upper Left - High Energy, Unpleasant)
  [
    {label: 'Angry', hex: '#B71C1C'},
    {label: 'Panicked', hex: '#C62828'},
    {label: 'Frustrated', hex: '#D32F2F'},
    {label: 'Anxious', hex: '#E53935'},
    {label: 'Nervous', hex: '#F44336'},
    {label: 'Stressed', hex: '#EF5350'},
  ],
  // Yellow Quadrant (Upper Right - High Energy, Pleasant)
  [
    {label: 'Excited', hex: '#FFBB00'},
    {label: 'Thrilled', hex: '#FFcf4d'},
    {label: 'Happy', hex: '#FFe600'},
    {label: 'Energetic', hex: '#FFEB3B'},
    {label: 'Cheerful', hex: '#FFEE58'},
    {label: 'Amused', hex: '#FFF176'},
  ],
  // Blue Quadrant (Lower Left - Low Energy, Unpleasant)
  [
    {label: 'Depressed', hex: '#1A237E'},
    {label: 'Bored', hex: '#283593'},
    {label: 'Disappointed', hex: '#303F9F'},
    {label: 'Sad', hex: '#3949AB'},
    {label: 'Lonely', hex: '#3F51B5'},
    {label: 'Tired', hex: '#5C6BC0'},
  ],
  // Green Quadrant (Lower Right - Low Energy, Pleasant)
  [
    {label: 'Content', hex: '#1B5E20'},
    {label: 'Relaxed', hex: '#2E7D32'},
    {label: 'Satisfied', hex: '#388E3C'},
    {label: 'Serene', hex: '#43A047'},
    {label: 'Peaceful', hex: '#4CAF50'},
    {label: 'Calm', hex: '#66BB6A'},
  ],
];

const MoodMeterTable = ({handleMoodClick}) => {
  return (
    <Box width='100%' sx={{display: 'flex',
      alignItems: 'flex-end'}}>
      {/* Vertical "Energy" Axis */}
      <Box sx={{
        display: 'flex',
        flexDirection: 'column-reverse',
      }}>
        <Typography variant="caption" sx={{
          transform: 'rotate(-90deg)',
          padding: '0',
          marginBottom: '40px',
          whiteSpace: 'nowrap', // Prevents wrapping
        }}>
          Energy →
        </Typography>
      </Box>
      <Box>
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          {quadrantMoods.map((quadrant, quadrantIndex) => (
            <Grid item xs={6} key={quadrantIndex}>
              <Grid container spacing={2}
                alignItems="center" justifyContent="center">
                {quadrant.map((mood, index) => (
                  <Grid item xs={4} key={index}>
                    <Paper
                      key={index}
                      onClick={() => handleMoodClick(mood)}
                      sx={{
                        padding: '8px',
                        textAlign: 'center',
                        color: mood.hex.startsWith('#FF')? '#000' : '#fff',
                        backgroundColor: mood.hex,
                        cursor: 'pointer',
                        height: '30px', // Adjust the height as needed
                      }}
                    >
                      {mood.label}
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ))}


        </Grid>
        <Grid item xs={12}>
          <p></p>
          <Typography variant="caption">Pleasantness →</Typography>
        </Grid>
      </Box>

    </Box>
  );
};
MoodMeterTable.propTypes = {
  handleMoodClick: PropTypes.func,
};
MoodMeterTable.defaultProps = {
  handleMoodClick: (mood) =>{
  },
};
export default MoodMeterTable;
