import React from 'react';
import Header from '../sections/header';
import DesktopFooter from '../sections/footer';
import WaitlistForm from '../sections/waitlistForm';
import {Box} from '@mui/material';

/**
 * Waitlist component
 * @param {Object} props - Component props
 * @param {Object[]} props.companyLinks - Company links
 * @param {Object[]} props.resourcesLinks - Resources links
 * @param {Object[]} props.partnersLinks - Partners links
 * @param {number} props.logoWidth - Logo width
 * @return {JSX.Element} - Rendered component
 */
export default function Waitlist() {
  return (
    <div>
      <Box sx={{
        position: 'sticky', top: 0, zIndex: 1000}}>
        <Header showLinks={false} btnLabel='Contact Us' btnLink='/contact'/>
      </Box>
      <Box height="100px"></Box>
      <WaitlistForm></WaitlistForm>
      <Box height="100px"></Box>
      <DesktopFooter> </DesktopFooter>
    </div>
  );
}
