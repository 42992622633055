
import React from 'react';

/**
 * Reloads the page to prompt the user to allow access to their
 * microphone and camera.
 * @return {void}
 */
const refreshPage = () => {
  console.log(
      // eslint-disable-next-line max-len
      'Make sure to allow access to your microphone and camera in your browser\'s permissions.',
  );
  window.location.reload();
};

/**
 * Renders a component to display an error message when the user's camera
 * or microphone is blocked.
 * @return {JSX.Element} The UserMediaError component.
 */
export default function UserMediaError() {
  return (
    <div>
      <div>
        <h1>Camera or mic blocked</h1>
        <button onClick={refreshPage}>Try again</button>
        <p>
          <a
            href="https://docs.daily.co/guides/how-daily-works/handling-device-permissions"
            target="_blank"
            rel="noreferrer noopenner"
          >
            Get help
          </a>
        </p>
      </div>
    </div>
  );
}
