import React, {useState} from 'react';
import {Box, IconButton, Grid} from '@mui/material';
import {
  ChevronLeft,
  ChevronRight,
  FiberManualRecord,
} from '@mui/icons-material';
import TwoColumnGrid from '../cards/valueCard';
import PropTypes from 'prop-types';
import valuesData from '../../models/values';

/**
 * Renders a carousel component with slides and navigation dots.
 * @param {Object} props - The component props.
 * @param {Object[]} props.slides - The slides to be displayed in the carousel.
 * @param {Object} props.SlideGrid
 *  - The component to be used for rendering each slide.
 * @param {number} props.width - The width of the carousel.
 * @return {JSX.Element} - The rendered component.
 */
function Carousel({slides, SlideGrid, width}) {
  const [activeSlide, setActiveSlide] = useState(0);
  const [isTransitionEnabled, setIsTransitionEnabled] = useState(true);

  const handlePrev = () => {
    if (activeSlide === 0) {
      setIsTransitionEnabled(false); // Disable transitions
      setActiveSlide(slides.length - 1);
      setTimeout(() => setIsTransitionEnabled(true), 0);
    } else {
      setActiveSlide((prevSlide) => prevSlide - 1);
    }
  };

  const handleNext = () => {
    if (activeSlide === slides.length - 1) {
      setIsTransitionEnabled(false); // Disable transitions
      setActiveSlide(0);
      setTimeout(() => setIsTransitionEnabled(true), 0);
    } else {
      setActiveSlide((prevSlide) => prevSlide + 1);
    }
  };

  /**
   * Handles the click event for the navigation dots.
   * @param {number} index - The index of the clicked dot.
   */
  const handleDotClick = (index) => {
    setActiveSlide(index);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        width: {xs: `${width+50}px`, sm: `calc(3* ${width+20}px)`},
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={0} sm={2} >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            sx={{
              display: {xs: 'none', sm: 'flex'},
            }}
          >
            <IconButton onClick={handlePrev}>
              <ChevronLeft />
            </IconButton>
          </Box>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Box
            position="relative"
            overflow="hidden"
            sx={{
              width: {xs: `${width+50}px`, sm: `calc(2* ${width+20}px)`},
              height: {xs: `500px`, sm: `350px`},
            }}
          >
            {slides.map((slide, index) => (
              <Box
                key={index}
                width="100%"
                height="100%"
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
                bgcolor="transparent"
                sx={{
                  transform: `translateX(${(index - activeSlide) * 100}%)`,
                  transition: isTransitionEnabled ?
                  'transform 0.5s ease' : 'none',
                }}
              >
                <SlideGrid width={width} data={slide}></SlideGrid>
              </Box>
            ))}
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box
            justifyContent="center"
            alignItems="center"
            height="100%"
            sx={{
              display: {xs: 'none', sm: 'flex'},
            }}
          >
            <IconButton onClick={handleNext}
              sx={{display: {xs: 'none', sm: 'flex'}}}>
              <ChevronRight />
            </IconButton>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" alignItems="center">
            {slides.map((_, index) => (
              <IconButton
                key={index}
                onClick={() => handleDotClick(index)}
                size="small"
              >
                <FiberManualRecord
                  fontSize="small"
                  color={index === activeSlide ? 'primary' : 'actionDisabled'}
                />
              </IconButton>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}


Carousel.propTypes = {
  /**
   * smallest slide width for mobile. passed to slide prop
   */
  width: PropTypes.number,
  /**
   * content for internal carousel slide.  See slide grid template
   */
  slides: PropTypes.shape([{
    image: PropTypes.object.isRequired,
    header: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }]),
  /** *
   * component for slide grid that goes in carousel
   */
  SlideGrid: PropTypes.any,
};

Carousel.defaultProps = {
  width: 250,
  slides: valuesData,
  SlideGrid: TwoColumnGrid,
};


export default Carousel;
