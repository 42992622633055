import React from 'react';
import {Box} from '@mui/material';
import {WidgetBarItem} from '../cards/widgetBarItem';
import PropTypes from 'prop-types';

export const WidgetBar = ({data}) => {
  return (
    <Box
      sx={{
        bgcolor: 'background.paper', // Shade color for the box
        p: 3, // Padding inside the box
        display: 'flex', // Use flexbox
        justifyContent: 'center', // Center align horizontally
        alignItems: 'center', // Center align vertically
        height: '100px', // Set a fixed height
        width: '100%', // Set a fixed width
      }}
      flexDirection="row"
    >
      {data.map((item, index) => (
        <WidgetBarItem key={index} label={item.label} value={item.value} />
      ))}
    </Box>
  );
};

WidgetBar.propTypes = {

  data: PropTypes.array,

};

WidgetBar.defaultProps = {
  data: [{'value': '33.33',
    'label': 'turn time'},
  {'value': '164',
    'label': 'words per minute'},
  {'value': '3',
    'label': 'interruptions'},
  {'value': '6',
    'label': 'turns'},
  {'value': '26%',
    'label': 'talk share'},
  ],
};
