import React from 'react';
import Header from '../sections/header';
import {useAuth0} from '@auth0/auth0-react';

const environment = process.env.REACT_APP_WEBENV;

/**
 * Header component displaying context-dependent button
 * @return {JSX.Element} - Rendered component
 */
export default function ContextualHeader() {
  const {
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();

  const startLogin = () => {
    console.log('initiating login process for user with redirect.');
    try {
      loginWithRedirect();
    } catch (e) {
      console.log(`log in with redirect failed due to ${e.message}`);
    }
  };


  const logoutWithRedirect = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });

  return (
    <div>
      {(environment == 'production') && (
        <Header showLinks={true}
          btnLabel='Contact Us'
          btnLink='/contact'/>
      )}
      {!isAuthenticated && (environment != 'production') && (
        <Header showLinks={true}
          btnLabel='Sign In'
          btnLink='/dashboard' btnOnClick={startLogin}/>
      )}
      {isAuthenticated && (environment != 'production') && (
        <Header showLinks={true}
          btnLabel='Sign Out' btnLink='/' btnOnClick={logoutWithRedirect}/>
      )}
    </div>
  );
}
