import React, {useCallback} from 'react';
import {
  useDaily,
  useLocalParticipant,
  useVideoTrack,
  useAudioTrack,
} from '@daily-co/daily-react-hooks';
import {Box, IconButton, Tooltip} from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import PanToolIcon from '@mui/icons-material/PanTool';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PropTypes from 'prop-types';

const VideoControls = ({leaveCall}) => {
  const callObject = useDaily();

  const localParticipant = useLocalParticipant();
  const localVideo = useVideoTrack(localParticipant?.session_id);
  const localAudio = useAudioTrack(localParticipant?.session_id);
  const mutedVideo = localVideo.isOff;
  const mutedAudio = localAudio.isOff;

  const toggleVideo = useCallback(() => {
    callObject.setLocalVideo(mutedVideo);
  }, [callObject, mutedVideo]);

  const toggleAudio = useCallback(() => {
    callObject.setLocalAudio(mutedAudio);
  }, [callObject, mutedAudio]);


  const leave = () => {
    leaveCall();
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="end"
      bgcolor="#111111"
      height='5%'
    >
      <Tooltip title="Microphone" sx={{
        color: !mutedAudio ? 'secondary' : 'primary.contrastText'}}
      onClick={toggleAudio}>
        <IconButton color={!mutedAudio ? 'secondary' : 'contrastText'}>
          {mutedAudio ? <MicOffIcon /> : <MicIcon />}
        </IconButton>
      </Tooltip>

      <Tooltip title="Camera" sx={{
        color: !mutedVideo ? 'secondary' : 'primary.contrastText'}}
      onClick={toggleVideo}>
        <IconButton color={!mutedVideo ? 'secondary' : 'contrastText'}>
          {mutedVideo ? <VideocamOffIcon /> : <VideocamIcon />}
        </IconButton>
      </Tooltip>

      <Tooltip title="Raise Hand">
        <IconButton color="primary">
          <PanToolIcon style={{color: 'white'}} />
        </IconButton>
      </Tooltip>

      <Tooltip title="React">
        <IconButton color="primary">
          <EmojiEmotionsIcon style={{color: 'white'}} />
        </IconButton>
      </Tooltip>

      <Tooltip title="Leave">
        <IconButton color="primary" onClick={leave} sx={{
          '&:hover': {
            color: (theme) => theme.palette.secondary.main,
          },
        }}>
          <ExitToAppIcon style={{color: 'white'}} />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

VideoControls.propTypes = {
  leaveCall: PropTypes.func,
};

export default VideoControls;
