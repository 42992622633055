import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ContextualHeader from '../sections/contextualHeader';
import DesktopFooter from '../sections/footer';
import {Box} from '@mui/material';
// import {topNavLinks} from '../../models/navlinks.js';
import VideoApp from '../sections/videoapp';
import {useAuth0} from '@auth0/auth0-react';
import {companyLinks,
  resourcesLinks, partnerLinks} from '../../models/navlinks.js';
import logger from '../../logger';
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

/**
 * Renders the Group Call page.
 * @param {Object} props - The component props.
 * @param {Object[]} props.companyLinks - The company links.
 * @param {Object[]} props.resourcesLinks - The resources links.
 * @param {Object[]} props.partnerLinks - The partners links.
 * @param {number} props.logoWidth - The width of the logo.
 * @return {JSX.Element} - The component JSX.
 */
export default function GroupCallPage({
  companyLinks,
  resourcesLinks,
  partnerLinks,
  logoWidth,
}) {
  const auth = useAuth0();
  const [accessToken, setAccessToken] = useState();

  const getToken = async () => {
    try {
      logger.info(`token audience value: ${audience}`);
      setAccessToken(await auth.getAccessTokenSilently({
        authorizationParams: {
          audience: audience,
          scope: `profile email demo join:demo view:app
          view:analytics join:video`,
        },
      }));
    } catch (e) {
      logger.info(`unable to get access token for user: ${e.message}`);
    }
  };

  useEffect(() => {
    logger.info(`getting token for user ${JSON.stringify(auth.user)}`);
    getToken();
  }, []);

  useEffect(() => {
    accessToken && logger.info(`token value changed: ${accessToken}`);
  },
  [accessToken]);

  return (

    <Box display={'flex'} flexDirection={'column'}>
      <Box sx={{position: 'sticky', top: 0, zIndex: 1000}}>
        <ContextualHeader />
      </Box>
      {accessToken ? <VideoApp token={accessToken}/> : <div>loading...</div>}
      <DesktopFooter companyLinks={companyLinks}
        partnersLinks={partnerLinks}
        resourcesLinks={resourcesLinks}
        logoWidth={logoWidth}/>
    </Box>

  );
}

GroupCallPage.propTypes = {
  companyLinks: PropTypes.array,
  resourcesLinks: PropTypes.array,
  partnerLinks: PropTypes.array,
  logoWidth: PropTypes.number,
};

GroupCallPage.defaultProps = {
  companyLinks: companyLinks,
  resourcesLinks: resourcesLinks,
  partnerLinks: partnerLinks,
  logoWidth: 40,
};
