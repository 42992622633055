import React from 'react';
import {Grid, Typography, Box, Paper} from '@mui/material';
import PropTypes from 'prop-types';
import valuesData from '../../models/values';

const dataElement = valuesData[0];

const TwoColumnGrid = ({data, width}) => {
  return (
    <Grid container spacing={1}
      sx={{
        width: {sm: `calc(3* ${width}px)`},
      }}>
      <Grid container item xs={12} alignItems="center" spacing={2}>
        <Grid item xs={12} sm={6}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Paper
              component="img"
              src={data.image}
              alt={data.header}
              sx={{
                maxWidth: {width},
                maxHeight: '250px',
                borderRadius: '8px',
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="start"
            sx={{padding: 2,
              xs: {
                width: {width}*.8,
              },
              md: {
                width: {width},
              },
            }}
          >
            <Typography variant="h6" gutterBottom>
              {data.header}
            </Typography>
            <Typography variant="body1">{data.text}</Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

TwoColumnGrid.propTypes = {
  /** *
   * max width of a single column (2x for two column grid etc)
   */
  width: PropTypes.number,
  /** *
   * data to populate grid items
   */
  data: PropTypes.shape({
    image: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
};

TwoColumnGrid.defaultProps = {
  width: '300px',
  data: dataElement,
};

export default TwoColumnGrid;
