import React from 'react';
import Header from '../sections/header';
import DesktopFooter from '../sections/footer';
import ContactForm from '../sections/contactForm';
import {Box} from '@mui/material';

/**
 * ContactUs component
 * @param {Object} props - Component props
 * @param {Array} props.companyLinks - Company links
 * @param {Array} props.resourcesLinks - Resources links
 * @param {Array} props.partnersLinks - Partners links
 * @param {number} props.logoWidth - Logo width
 * @return {JSX.Element} - Rendered ContactUs component
 */
export default function ContactUs() {
  return (
    <div>
      <Box sx={{position: 'sticky', top: 0, zIndex: 1000}}>
        <Header showLinks={false}
          btnLink='/waitlist' btnLabel='Join Waitlist' />
      </Box>
      <Box height='100px' />
      <ContactForm />
      <Box height='100px' />
      <DesktopFooter />
    </div>
  );
}
