import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import {Link} from 'react-router-dom';
import {HashLink} from 'react-router-hash-link';
import PropTypes from 'prop-types';
import {topNavLinks} from '../../models/navlinks.js';
// default links for nav menus
const links = topNavLinks;

export const HorizontalButtonGroup = ({
  showLinks,
  links,
  groupVariant,
  color,
}) => {
  return (
    <Box
      sx={{
        'display': showLinks ? 'flex' : 'none',
        'flexDirection': 'column',
        'alignItems': 'center',
        '& > *': {
          m: 1,
        },
      }}
    >
      <ButtonGroup variant={groupVariant}
        color={color} aria-label="text button group">
        {links.map((link, index) => (
          <Button
            key={link.name} // add a unique key prop
            variant={link.variant}
            component={link.path.includes('#') ? HashLink : Link}
            color={color}
            to={link.path}
          >{link.name}
          </Button>
        ))}
      </ButtonGroup>
    </Box>
  );
};

HorizontalButtonGroup.propTypes = {
  /**
     * if true, shows the links
     */
  showLinks: PropTypes.bool,

  /**
     * a list of key-value pairs of navigation
     * links with the link name and path:
     *  const links = [{ name: 'Partners', path: '/#partners' },..],
     */
  links: PropTypes.array,
  /**
     * type of button group
     */
  groupVariant: PropTypes.oneOf(['text', 'outlined', 'contained']),
  /** *
     * color of button group
     */
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'error',
    'info',
    'warning',
  ]),

};

HorizontalButtonGroup.defaultProps = {
  showLinks: true,
  links: links,
  groupVariant: 'text',
  color: 'primary',
};

