import React from 'react';
import PropTypes from 'prop-types';
import {withAuthenticationRequired} from '@auth0/auth0-react';

const AuthenticationGuard = ({component: Component}) => {
  const AuthenticatedComponent = withAuthenticationRequired(Component, {
    onRedirecting: () => <div>Loading ...</div>,
  });
  console.log('AuthenticatedComponent ok ', Component.name);

  return <AuthenticatedComponent />;
};

AuthenticationGuard.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default AuthenticationGuard;
