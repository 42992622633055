/**
 * Returns the room URL from the page URL.
 * @return {string|null} The room URL or null if not found.
 */
export function roomUrlFromPageUrl() {
  const match = window.location.search.match(/roomUrl=([^&]+)/i);
  return match && match[1] ? decodeURIComponent(match[1]) : null;
}

/**
 * Returns the page URL from the room URL.
 * @param {string|null} roomUrl - The room URL.
 * @return {string} The page URL.
 */
export function pageUrlFromRoomUrl(roomUrl) {
  return (
    window.location.href.split('?')[0] +
      (roomUrl ? `?roomUrl=${encodeURIComponent(roomUrl)}` : '')
  );
}
