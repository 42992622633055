import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import {Typography} from '@mui/material';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {styled} from '@mui/material/styles';
import {companyLinks} from '../../models/navlinks';
// default links for nav menus
const defaultLinks = companyLinks;

const StyledButton = styled(Button)(({theme}) => ({
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'start',
  fontSize: 12,
  color: theme.palette.primary,
}));

export const VerticalButtonGroup = ({
  links,
  title,
}) => {
  return (
    <Box
      sx={{
        'display': 'flex',
        'alignItems': 'flex-start',
        'justifyContent': 'start',
        'flexDirection': 'column',
        '& > *': {
          m: 1,
        },
      }}
    >
      <Typography
        variant="subtitle1"
        gutterBottom
        align='left'
        sx={{marginBottom: '-5px'}}
      >{title}</Typography>
      <ButtonGroup
        variant="text"
        orientation='vertical'
        size='small'
        aria-label="text button group"
      >
        {links.map((link, index) => (
          <StyledButton
            key={link.name} // use a unique identifier for each link
            component={Link}
            to={link.path}
            target="_blank"
            rel="noopener noreferrer"
          >
            {link.name}
          </StyledButton>
        ))}
      </ButtonGroup>
    </Box>
  );
};

VerticalButtonGroup.propTypes = {

  /**
     * a list of key-value pairs of navigation
     * links with the link name and path:
     *  const links = [{ name: 'Partners', path: '/#partners' },..],
     */
  links: PropTypes.array,
  /**
     *
     * title of the button group
     */
  title: PropTypes.string,
};

VerticalButtonGroup.defaultProps = {

  links: defaultLinks,
  title: 'Title',
};

