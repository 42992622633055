// src/App.js
import {ThemeProvider}
  from '@mui/material/styles'; // Import ThemeProvider and createTheme
// eslint-disable-next-line max-len
import React, {useState} from 'react';
import '@fontsource/poppins/200.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/800.css';
import AuthenticationGuard from './components/shared/authentication-guard';
import {lightTheme as theme} from './assets/theme';
import Home from './components/pages/home';
import Product from './components/pages/product';
import ContactUs from './components/pages/contact';
import Waitlist from './components/pages/waitlist';
import Soon from './components/pages/comingSoon';
import GroupCallPage from './components/pages/groupCall';
import Dashboard from './components/pages/dashboard';
import SelectedIdContext from './models/selectedContext';

import {Route, Routes} from 'react-router-dom';

import logger from './logger';


const App = () => {
  logger.info('App load successful!');
  const [selectedId, setSelectedId] = useState(null);
  return (
    <ThemeProvider theme={theme}>
      <SelectedIdContext.Provider value={{selectedId, setSelectedId}}>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/product' element={<Product />} />
          <Route path='/about' element={<Home />} />
          <Route path='/contact' element={<ContactUs />} />
          <Route path='/waitlist' element={<Waitlist/>} />
          <Route path='/soon' element={<Soon/>} />
          <Route
            path='/dashboard'
            element={<AuthenticationGuard component={Dashboard} />}
          />
          <Route
            path='/app'
            element={<AuthenticationGuard component={GroupCallPage} />}
          />
        </Routes>
      </SelectedIdContext.Provider>
    </ThemeProvider>
  );
};

export default App;

