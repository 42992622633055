import React, {useEffect, useState, useContext} from 'react';
import PropTypes from 'prop-types';
import DesktopFooter from '../sections/footer';
import {DefaultButton} from '../navigation/button';
import {useAuth0} from '@auth0/auth0-react';
import {

  Box,
} from '@mui/material';
import DemoPersona from '../cards/demoPersona';
import SelectedIdContext from '../../models/selectedContext';
import ContextualHeader from '../sections/contextualHeader';

const environment = process.env.REACT_APP_WEBENV;

/**
 * Dashboard component that displays the user's dashboard.
 * @param {Object} props - The props object for the component.
 * @param {Array} props.companyLinks - The links for the company.
 * @param {Array} props.resourcesLinks - The links for the resources.
 * @param {Array} props.partnersLinks - The links for the partners.
 * @param {number} props.logoWidth - The width of the logo.
 * @return {JSX.Element} - The Dashboard component.
 */
export default function Dashboard() {
  const {selectedId, setSelectedId} = useContext(SelectedIdContext);

  const HandleSelect = (id) => {
    setSelectedId(id);
  };

  const [loginState, setLoginState] = useState(0);

  useEffect(() => {
    loginState === 0 && selectedId && setLoginState(1);
    console.log(selectedId);
  }, [selectedId]);

  const {
    isAuthenticated,
  } = useAuth0();

  // console.log(`Got params from auth0.  User: ${user},
  // Auth status: ${isAuthenticated}, environment: ${environment}`);

  return (
    <div>
      <Box sx={{position: 'sticky', top: 0, zIndex: 1000}}>
        <ContextualHeader />
      </Box>
      {isAuthenticated && (environment != 'production') && (
        <DemoPersona onSelect={HandleSelect}></DemoPersona>
      )}
      <Box sx={{display: 'flex',
        justifyContent: 'center', marginTop: '20px', marginBottom: '20px'}}>
        {isAuthenticated && selectedId && (environment != 'production') && (
          <DefaultButton label='Ready to Check In!' linkPage='/app' />
        )}
      </Box>
      <DesktopFooter />

    </div>
  );
}

Dashboard.propTypes = {
  companyLinks: PropTypes.array.isRequired,
  resourcesLinks: PropTypes.array.isRequired,
  partnersLinks: PropTypes.array.isRequired,
  logoWidth: PropTypes.number.isRequired,
};


