
import React from 'react';
import {Box, Button, Typography, useMediaQuery} from '@mui/material';
import PropTypes from 'prop-types';

const VideoJoin = ({startCall}) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const start = () => {
    startCall();
  };
  return (
    <div>
      <Typography variant="h1" sx={{color: 'white',
        fontSize: {xs: '1rem', sm: '1.2rem', m: '1.5rem'}}}>
      CALL DEMO <p></p>
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: 'white',
          fontSize: {xs: '.7rem', sm: '1rem'},
        }}>
        {isSmallScreen ? 'Tap the button below to join' :
          `For now this will create a new room AND join the room. In
          the future, this will be hooked to a URL for a room and the
          button will only join the existing room, not create a new
          one.`
        }
      </Typography>
      <Box height="50px"></Box>
      <Button variant="contained" onClick={start}>
      JOIN
      </Button>
      <Box height="50px"></Box>
      <Typography variant="body1" sx={{
        color: '#f44336',
        fontSize: {xs: '.7rem', sm: '1rem'}}}>
      IMPORTANT: Select “Allow” to use your camera and mic for
      this call if prompted.
      </Typography>
    </div>);
};

VideoJoin.propTypes ={
  startCall: PropTypes.any,
};

VideoJoin.defaultProps = {
  startCall: () => {
    console.log('starting call');
  },
};

export default VideoJoin;
