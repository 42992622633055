/* eslint-disable require-jsdoc */
import React, {useEffect, useRef, useState, useCallback} from 'react';
import {
  useParticipantIds,
  useVideoTrack,
  useLocalParticipant,
  useDailyEvent,
  useParticipantProperty,
} from '@daily-co/daily-react-hooks';
import VideoTile from './videoTile';
import UserMediaError from './UserMediaError';
import {Grid, Box, Typography} from '@mui/material';

// eslint-disable-next-line react/prop-types
const Participant = ({id, isLocal}) => {
  const userName = useParticipantProperty(id, 'user_name') || id;

  return (
    <div>
      {/* Conditionally mute the video for the local participant */}
      <VideoTile id={id} muted={isLocal} />
      <Typography variant='bodylight'
        width={'100%'}
        sx={{position: 'absolute',
          left: '0', bottom: '0'}}>{userName}</Typography>
    </div>
  );
};

export default function Call() {
  const [getUserMediaError, setGetUserMediaError] = useState(false);
  const localParticipant = useLocalParticipant();
  const localParticipantVideoTrack =
   useVideoTrack(localParticipant?.session_id);
  const localVideoElement = useRef(null);

  useEffect(() => {
    if (!localParticipantVideoTrack.persistentTrack) return;
    localVideoElement?.current &&
       (localVideoElement.current.srcObject = new
       MediaStream([localParticipantVideoTrack?.persistentTrack]));
  }, [localParticipantVideoTrack.persistentTrack]);

  const participantIds = useParticipantIds().filter((id) =>
    id !== localParticipant?.session_id);

  useDailyEvent(
      'camera-error',
      useCallback((ev) => {
        setGetUserMediaError(true);
      }, []),
  );

  const renderCallScreen = () => {
    return (
      <Box id="main" height="100%" style={{position: 'relative'}}>
        <Grid container style={{height: '100%'}}>
          {/* Render the local participant separately and mute their video */}
          {localParticipant && (
            <Grid item xs={4}>
              <Box m={1} padding={1}
                sx={{border: '1px solid white',
                  borderRadius: '0', position: 'relative'}}>
                <Participant id={localParticipant.session_id} isLocal={true} />
              </Box>
            </Grid>
          )}
          {participantIds.map((id) => (
            <Grid item xs={4} key={id}>
              <Box m={1} padding={1} sx={{border: '1px solid white',
                borderRadius: '0', position: 'relative'}}>
                <Participant id={id} />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };

  return <>{getUserMediaError ? <UserMediaError /> : renderCallScreen()}</>;
}
