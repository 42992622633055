import React from 'react';
import PropTypes from 'prop-types';
import {Box} from '@mui/material';
import Logo from '../shared/logo.jsx';
import {DefaultButton} from '../navigation/button';
import {HorizontalButtonGroup} from '../navigation/horizontalButtonGroup.jsx';

import {topNavLinks} from '../../models/navlinks.js';

/**
 * Header component
 * @return {JSX.Element} - Rendered component
 */
export default function Header(
    {showLinks, links, logoWidth, btnLabel, btnLink, btnOnClick}) {
  return (
    <section id="Header">
      <Box
        sx={{
          boxShadow: 3,
          display: 'flex',
          justifyContent: {xs: 'center', sm: 'Space-between'},
          alignItems: 'center',
          width: '100%',
          flexWrap: 'wrap',
          bgcolor: '#ffffff',
          flexDirection: {xs: 'column', sm: 'row'},
        }}
      >
        <Box sx={{display: {xs: 'none', md: 'block'}}}>
          <HorizontalButtonGroup
            showLinks={showLinks} links={links}></HorizontalButtonGroup>
        </Box>
        <Box sx={{marginRight: {xs: '10px', sm: '200px'}}}>
          <a href="/">
            <Logo width={logoWidth}></Logo>
          </a>
        </Box>
        <Box sx={{marginRight: '20px', marginBottom: '10px'}}>
          <DefaultButton
            label={btnLabel}
            primary={false}
            variantName='outlined'
            linkPage={btnLink}
            color='secondary'
            onClick={btnOnClick}
          ></DefaultButton>
        </Box>
      </Box>
    </section>
  );
}

Header.propTypes = {

  /**
     * if true, shows the links
     */
  showLinks: PropTypes.bool,


  /*
    **width of logo
    */
  logoWidth: PropTypes.number,
  /*
    **text on secondary button
    */
  btnLabel: PropTypes.string,
  /** *
     * link path on secondary button
     */
  btnLink: PropTypes.string,
  /*
   * a list of key-value pairs of navigation links with the link name and path:
   *  const links = [{ name: 'Partners', path: '/#partners' },..],
    */
  links: PropTypes.array,
  btnOnClick: PropTypes.func,

};

Header.defaultProps = {
  showLinks: true,
  logoWidth: 150,
  btnLabel: 'Secondary Button',
  btnLink: '/',
  links: topNavLinks,

};

