import React, {useState} from 'react';
import {Button, TextField, Typography, Container, Box} from '@mui/material';
import {sendEmail} from '../../api/emailadmin';
/**
 * Contact component
 * @return {JSX.Element} - Rendered component
 */
function ContactForm() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [submitStatus, setSubmitStatus] = useState('');

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const result = await sendEmail(formData);
    setSubmitStatus(result);
  };

  return (
    <Container component="main" maxWidth="sm">
      <Typography variant="h6" align="center">
                Contact Us
      </Typography>
      <Typography variant="subtitle1" align="center">
                We&apos;d Love to Hear From You
      </Typography>
      <p> </p>
      <Typography variant="body2" align="center">
Want to help?  We are actively seeking out investors,
partners and collaborators to help us realize our
vision to leverage the power of technology to help all
people form social connections in an inclusive, supportive environment.
Send us a message at the form below and we will get back
to you as soon as we can.
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="Contact-01-name"
          label="Name"
          name="name"
          autoComplete="name"
          autoFocus
          value={formData.name}
          onChange={handleChange}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="Contact-01-email"
          label="Email"
          name="email"
          autoComplete="email"
          value={formData.email}
          onChange={handleChange}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          multiline
          rows={4}
          name="message"
          label="Message"
          id="Contact-01-message"
          value={formData.message}
          onChange={handleChange}
        />
        <Box display="flex" justifyContent="center">
          <Button
            type="submit"
            variant="contained"
            color="primary"
          >
                    Send Message
          </Button>
        </Box>
      </form>
      {submitStatus && (
        <Typography variant="body1" align="center" style={{marginTop: '15px'}}>
          {submitStatus}
        </Typography>
      )}
    </Container>
  );
}

export default ContactForm;
