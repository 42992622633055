/* eslint-disable max-len */
const VERSION = '1.0.0';

const logger = {
  info: (...messages) => console.log(`Prosocial Dev: ${VERSION} #####`, ...messages),
  warn: (...messages) => console.warn(`Prosocial Dev: ${VERSION} #####`, ...messages),
  error: (...messages) => console.error(`Prosocial Dev: ${VERSION} #####`, ...messages),
  // Add other console methods as needed
};

export default logger;
