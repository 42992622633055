/* eslint-disable max-len */
import _ from 'lodash';
import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  createContext,
  useMemo,
} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import DailyIframe from '@daily-co/daily-js';
// import {useLocalSessionId} from '@daily-co/daily-react';
import {DailyProvider} from '@daily-co/daily-react-hooks';
import {fetchRoomData} from '../../api/dailyapi';
import {roomUrlFromPageUrl, pageUrlFromRoomUrl} from '../../utils/dailyutils';
import {Box, Grid} from '@mui/material';
import Call from '../cards/video/videoCall';
import VideoControls from '../cards/video/videoControls';
import VideoError from '../cards/video/videoError';
import VideoJoin from '../cards/video/videoJoin';
import PropTypes from 'prop-types';
import ChatStream from '../cards/chatstream';
import {WidgetBar} from './widgetBar';
import CheckIn from './checkIn';
import TimelineAgenda from '../cards/timelineAgenda';
/* We decide what UI to show to users based on the
state of the app, which is dependent on the state of the call
object: see line 137. */
const STATE_IDLE = 'STATE_IDLE';
const STATE_READY = 'STATE_READY';
const STATE_CREATING = 'STATE_CREATING';
const STATE_JOINING = 'STATE_JOINING';
const STATE_JOINED = 'STATE_JOINED';
const STATE_LEAVING = 'STATE_LEAVING';
const STATE_ERROR = 'STATE_ERROR';
import logger from '../../logger';
export const DataContext = createContext();
const MAX_MESSAGES = 100;

const DEFAULT_DATA =
  [
    {icon: 'love', label: 'Coach Bot', timestamp: '2023-04-01T15:30:00.000Z',
      message: 'Positive feedback will look like this'},
    {icon: 'notify', label: 'Coach Bot', timestamp: '2023-04-01T15:30:00.000Z',
      message: 'Suggestions will look like this'},
    {icon: 'target', label: 'Talk Target',
      timestamp: '2023-04-01T15:30:00.000Z',
      message: 'talk targets will look like this'},
    {icon: 'alert', label: '', timestamp: '2023-04-01T15:30:00.000Z',
      message: 'Alert messages will look like this'},
  ];
;

/**
 * Video App from daily
 * @return {JSX.Element} - Rendered component
 */
const VideoApp = ({
  token,
  label,
  explanation,
  targetData,
}) => {
  const [chatHistory, setChatHistory] = useState(DEFAULT_DATA);
  const [checkInMessage, setCheckInMessage] = useState('');
  const [appState, setAppState] = useState(STATE_IDLE);
  const [roomUrl, setRoomUrl] = useState(null);
  const [callObject, setCallObject] = useState(null);
  const [apiError, setApiError] = useState(false);
  const [room, setRoom] = useState(null);
  const [context, setContext] = useState([]);
  const [participants, setParticipants] = useState(null);
  const serverEndpoint = process.env.REACT_APP_PROSOCIAL_BACKEND;
  const {user} = useAuth0();
  const coachEventRef = useRef(null);

  const coachEndpoint = useMemo(() => {
    return participants ?
      `${serverEndpoint}/users/${participants.local.session_id}/feedback/` :
      // `${serverEndpoint}/users/test/feedback/` :
      null;
  }, [participants, serverEndpoint]);

  const showCall = [STATE_JOINING, STATE_JOINED, STATE_ERROR].includes(
      appState,
  );
  /**
   * Function to update chatHistory with new message
   *
   * @param {array} chatHistory
   * @param {object} newMessage
   * @return {array} updated chat history
   *
   **/
  function updateChatHistory(chatHistory, newMessage) {
    if (chatHistory == DEFAULT_DATA) {
      return [newMessage];
    } else {
      const updatedHistory = [...chatHistory, newMessage];
      logger.info('Updated chat history', updatedHistory);
      if (updatedHistory.length > MAX_MESSAGES) {
        return updatedHistory.slice(1); // Remove the oldest message
      }
      return updatedHistory;
    }
  }

  /**
   * Custom hook for managing an EventSource connection.
   *
   * @param {string} endpoint - The endpoint URL for the EventSource connection.
   * @param {function} setEventState - The state setter function for the EventSource instance.
   * @param {function} onMessage - The callback function to handle incoming messages.
   */
  const useEventSource = (endpoint, setEventState) => {
    logger.info('setting up event source');
    const lastEndpoint = useRef(endpoint);
    logger.info(lastEndpoint);
    useEffect(() => {
      if (!endpoint || endpoint === lastEndpoint.current) return;

      const eventSource = new EventSource(endpoint);
      setEventState.current = eventSource;

      const onEnd = (event) => {
        logger.info('Ended coach event source for '+endpoint);
        eventSource.close(); // Close the EventSource
      };
      eventSource.addEventListener('coach_bot_update',
          (event) => {
            const data = JSON.parse(event.data);
            logger.info('Received coaching messages from app', data);
            const newMessage = data.feedback;
            const ts = data.id.substring(3);
            logger.info('Feedback', newMessage);
            for (let i = 0; i<newMessage.length; i++) {
              newMessage[i].label = 'Coach Bot';
              newMessage[i].icon = newMessage[i].type == 'positive' ? 'love' : 'notify';
              newMessage[i].timestamp = ts;
              setChatHistory((chatHistory) => updateChatHistory(chatHistory, newMessage[i]));
            }
          });
      eventSource.addEventListener('talk_target_status',
          (event) => {
            const data = JSON.parse(event.data);
            logger.info('Received talk target status update from app', data);
            const newMessage = data;
            logger.info('Talk target status', newMessage);
            if (newMessage.target_met) {
              newMessage.label = '';
              newMessage.icon = 'alert';
              newMessage.message = 'Talk target achieved!'+newMessage.explanation;
              newMessage.timestamp = newMessage.id.substring(3);
              setChatHistory((chatHistory) => updateChatHistory(chatHistory, newMessage));
            }
          });
      eventSource.addEventListener('talk_target_tips',
          (event) => {
            const data = JSON.parse(event.data);
            logger.info('Received talk target tip from app', data);
            const newMessage = data;
            logger.info('Talk target tip', newMessage);
            newMessage.label = 'Talk Target';
            newMessage.icon = 'target';
            newMessage.message = newMessage.suggestions[0];
            newMessage.timestamp = newMessage.id.substring(3);
            setChatHistory((chatHistory) => updateChatHistory(chatHistory, newMessage));
          });
      eventSource.addEventListener('user_metric_update',
          (event) => {
            const data = JSON.parse(event.data);
            logger.info('Received user metrics from app', data);
            const updatedData = [];
            // eslint-disable-next-line guard-for-in
            for (const key in data) {
              const value = data[key];
              if (typeof value === 'number' && value !== null) {
                updatedData.push({'label': _.startCase(key), 'value': String(value)});
              }
            }
            logger.info('Updated user metrics', updatedData);
            setContext(updatedData);
          });
      eventSource.addEventListener('end_event', onEnd);
    }, [endpoint, setEventState]);
  };

  logger.info(`coaching endpoint set to ${coachEndpoint}`);
  useEventSource(
      // eslint-disable-next-line max-len
      coachEndpoint,
      coachEventRef,
  );

  /**
   * Creates a new call room with daily api and returns the room's url.
   */

  const createCall = useCallback(async () => {
    try {
      logger.info(`Beginning call set up with token ${token}`);
      const isDemo = true;
      setAppState(STATE_CREATING);
      // For demo purposes only - need to modify for prod
      const roomData = await fetchRoomData(token, isDemo);
      setRoom(roomData);
    } catch (error) {
      logger.info('Error creating room: ', error);
      setRoomUrl(null);
      setAppState(STATE_IDLE);
      setApiError(true);
    }
  }, []);

  /**
   * Starts joining an existing call.
   *
   * NOTE: In this demo we show how
   * to completely clean up a call with destroy(),
   * which requires creating a new call object before you can join() again.
   * This isn't strictly necessary, but is good practice when you know you'll
   * be done with the call object for a
   * while and you're no longer listening to its
   * events.
   */

  const startJoiningCall = useCallback((url, token) => {
    logger.info('Starting call for room: ' + url);
    const newCallObject = DailyIframe.createCallObject({userName: user.name});
    setRoomUrl(url);
    setCallObject(newCallObject);
    setAppState(STATE_JOINING);
    newCallObject.join({url, token});
  }, []);

  const startCall = () => {
    createCall();
  };

  /**
   * Starts leaving the current call.
   */
  const startLeavingCall = useCallback(() => {
    if (!callObject) return;
    // If we're in the error state, we've already "left", so just clean up
    if (appState === STATE_ERROR) {
      callObject.destroy().then(() => {
        setRoomUrl(null);
        setCallObject(null);
        setAppState(STATE_IDLE);
      });
    } else {
      /* This will trigger a `left-meeting`
      event, which in turn will trigger the full clean-up
      as seen in handleNewMeetingState() below. */
      setAppState(STATE_LEAVING);
      callObject.leave();
    }
  }, [callObject, appState]);

  /**
   * If a room's already specified in the page's URL when the component mounts,
   * join the room.
   */
  useEffect(() => {
    const url = roomUrlFromPageUrl();
    url && startJoiningCall(url);
  }, [startJoiningCall]);

  /**
   * Update the page's URL to reflect the active call when roomUrl changes.
   */
  useEffect(() => {
    const pageUrl = pageUrlFromRoomUrl(roomUrl);
    if (pageUrl === window.location.href) return;
    window.history.replaceState(null, null, pageUrl);
  }, [roomUrl]);


  /**
   * Submits the check-in data.
   * @param {any} data - The check-in data to be submitted.
   */
  function checkInSubmit(data) {
    setCheckInMessage(data);
    logger.info(checkInMessage);
    setAppState(STATE_READY);
  };

  /**
   * Update app state based on reported meeting state changes.
   *
   * NOTE: Here we're showing how to completely clean up a call with destroy().
   * This isn't strictly necessary between join()s, but is good practice when
   * you know you'll be done with the call object for a while, and you're no
   * longer listening to its events.
   */

  useEffect(() => {
    if (!callObject) return;

    const events = ['joined-meeting', 'left-meeting', 'error', 'camera-error'];

    /**
     * function to handle new meeting, join and leave

     */
    function handleNewMeetingState() {
      switch (callObject.meetingState()) {
        case 'joined-meeting':
          setAppState(STATE_JOINED);
          setParticipants(callObject.participants());
          break;
        case 'left-meeting':
          callObject.destroy().then(() => {
            setRoomUrl(null);
            setCallObject(null);
            setAppState(STATE_READY);

            if (coachEventRef instanceof EventSource &&
              coachEventRef.readyState === EventSource.OPEN) {
              coachEventRef.close();
            };
          });
          break;
        case 'error':
          setAppState(STATE_ERROR);
          break;
        default:
          break;
      }
    }

    // Use initial state
    handleNewMeetingState();

    // Listen for changes in state
    for (const event of events) {
      callObject.on(event, handleNewMeetingState);
    }

    // Stop listening for changes in state
    return function cleanup() {
      for (const event of events) {
        callObject.off(event, handleNewMeetingState);
      }
    };
  }, [callObject]);

  useEffect(() => {
    if (room) {
      logger.info(`room data: ${JSON.stringify(room)}`);
      logger.info(`room url: ${room.room_url}`);
      startJoiningCall(room.room_url, room.token);
    }
  }, [room]);

  return (
    <Grid container direction='row'
      sx={{alignItems: 'center', justifyContent: 'center'}}>

      {/* This Grid contains both VideoApp and WidgetBox components */}
      {appState === STATE_IDLE? <CheckIn handleSubmit={checkInSubmit}></CheckIn> :
      <DataContext.Provider value={context}>
        <Grid item xs={12} sx={{padding: '20px'}}>
          <TimelineAgenda data={targetData} />
        </Grid>
        <Grid item xs={9}>

          <Grid container direction='row'
            sx={{
              minHeight: {
                xs: '300px', // minHeight on extra-small screens and up
                sm: '400px', // minHeight on small screens and up
                md: '500px', // minHeight on medium screens and up
                lg: '600px', // minHeight on large screens and up
                xl: '700px', // minHeight on extra-large screens and up
              },
              minWidth: {
                xs: '300px', // minHeight on extra-small screens and up
                sm: '400px', // minHeight on small screens and up
                md: '500px', // minHeight on medium screens and up
                lg: '600px', // minHeight on large screens and up
                xl: '700px', // minHeight on extra-large screens and up
              },
              height: '90vh',
              // backgroundColor: 'grey.100',
              overflow: 'hidden'}}>

            {/* VideoApp Component */}


            <Grid item xs={12} p={1} height="85%" ml={2}>
              <DailyProvider callObject={callObject}>
                <VideoControls leaveCall={startLeavingCall} />
                <Box
                  sx={{
                    flex: 1,
                    backgroundColor: '#000',
                    alignContent: 'center',
                    textAlign: 'center',
                    height: '95%',
                  }}
                >
                  {apiError ? (
                    <VideoError/>
                  ) : showCall ? (
                    <Call />
                  ) : (
                    <VideoJoin startCall={startCall} />
                  )}
                  ;
                </Box>
              </DailyProvider>

            </Grid>

            <Grid
              item xs={12}
              height="15%"
              sx={{
                overflowY: 'auto',
              }}>
              <Box padding={'0px'} marginLeft={'100px'} sx={{
                border: '0px solid red',
              }}>{appState != STATE_IDLE && <WidgetBar data={context}/>}
              </Box>
            </Grid>

          </Grid>

        </Grid>
        <Grid
          item xs={3}
          sx={{
            overflowY: 'auto',
            minHeight: {
              xs: '300px', // minHeight on extra-small screens and up
              sm: '400px', // minHeight on small screens and up
              md: '500px', // minHeight on medium screens and up
              lg: '600px', // minHeight on large screens and up
              xl: '700px', // minHeight on extra-large screens and up
            },
            height: '90vh',
          }}>
          <Box padding={'20px'} sx={{
            height: '98%',
            border: '0px solid black',
            background: 'white',
          }}>
            {appState != STATE_IDLE && <ChatStream chatHistory={chatHistory} />}
          </Box>
        </Grid>

      </DataContext.Provider>
      }
    </Grid>

  );
};

VideoApp.propTypes = {
  token: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  explanation: PropTypes.string.isRequired,
  targetData: PropTypes.array.isRequired,
};

VideoApp.defaultProps={
  label: 'Target Label',
  activeSpeaker: 'Lara',
  explanation: `Talk targets are participation goals that are unique to you.
  Each target includes an explanation and suggestions to help you participate.`,
  targetData: [
    {label: 'Default Step 1', checkstate: true, explanation: 'This is the first step'},
    {label: 'Default Step 2', checkstate: true, explanation: 'This is the second step'},
    {label: 'Default Step 3', checkstate: false, explanation: 'This is the third step'},
    {label: 'Default Step 3', checkstate: false, explanation: 'This is the third step'},
    {label: 'Default Step 3', checkstate: false, explanation: 'This is the third step'},
  ],
};

export default VideoApp;
