import React from 'react';
import {Grid, Typography} from '@mui/material';
import listen from '../../assets/images/FC-Listen.svg';
import PropTypes from 'prop-types';

const skills = {
  title: 'Self-Expression',
  image: listen,
  imgDesc: 'a small icon of headphones on a light purple background',
  value: 'Level 1 Skill',
  description:
      'Self-expression is how you share your own ideas, feelings, and '+
      'thoughts clearly and authentically. Skills include:',
  skills: [
    'sharing ideas',
    'using non-verbal cues',
    'being clear and concise',
    'varying tone of voice',
  ],
};

const FocusArea = (props) => {
  const {skills} = props;
  return (
    <div>
      <Typography variant='subtitle1' fontWeight='bold'>
        {skills.title}
      </Typography>
      <Typography variant='body1'>
        {skills.value}
      </Typography>
      <br />
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <img src={skills.image} alt={skills.imgDesc}
            style={{maxWidth: '100px'}}/>
        </Grid>
        <Grid item xs={8}>
          <Typography variant='body2'>
            {skills.description}
          </Typography>
          <Typography variant='body2' component='div'>
            <ul>
              {skills.skills.map((skill, index) => (
                <li key={index}>{skill} </li>
              ))}
            </ul>
          </Typography>
        </Grid>
      </Grid>
    </div>

  );
};

FocusArea.propTypes = {
  /** *
   * data to populate grid items
   */
  skills: PropTypes.shape(
      {
        title: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
        imgDesc: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        skills: PropTypes.arrayOf(PropTypes.string).isRequired,
      },
  ),
};

FocusArea.defaultProps = {
  skills: skills,
};

export default FocusArea;
